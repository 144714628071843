

@media screen and (min-width: 0px) and (max-width: 420px) {
    .welcome {
		background-image: url('./img/doublebed.jpg');
		background-size: 100vw;
		background-position: 50% 100%;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.overview {
		background-image: url('./img/sinkiatthani.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.room {
		background-image: url('./img/doublebed2.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.attraction {
		background-image: url('./img/twinbed.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.booknow {
		background-image: url('./img/twinbed.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.gallery {
		background-image: url('./img/doublebed3.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.contact {
		background-image: url('./img/twinbed2.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.booking {
		background-image: url('./img/twinbed3.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}	
}

@media screen and (min-width: 421px) {
    .welcome {
		background-image: url('./img/doublebed.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.overview {
		background-image: url('./img/sinkiatthani.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.room {
		background-image: url('./img/doublebed2.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.attraction {
		background-image: url('./img/twinbed.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.booknow {
		background-image: url('./img/twinbed.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.gallery {
		background-image: url('./img/doublebed3.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}	
	.contact {
		background-image: url('./img/twinbed2.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.booking {
		background-image: url('./img/twinbed3.jpg');
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
}

